<template>
    <div class="lct-detail-content">
        <div class="lct-detail-head">
            <div class="lct-detail-head-main">
                <div class="lct-detail-head-crumbs" v-if="info.title">
                    <span class="on">直播</span><i class="iconfont icon-jiantou"></i><router-link to="/seminars">讲座会议</router-link><i class="iconfont icon-jiantou"></i><span>{{ info.title }}</span>
                </div>

                <div class="new-detail-head">
                    <div class="lct-detail-head-banner" v-if="info.image">
                        <!--<img :src="info.banner"/>-->
                        <el-image style="width: 100%;" :src="info.image" fit="cover"></el-image>
                    </div>
                    <div class="new-detail-info">
                        <div class="lct-detail-head-title">
                            <h4>{{ info.title }}</h4>
                            <div class="share-show">
                                <img class="wx" src="https://image.ysxxlm.cn/ysxxlm/pc/static/img/joinUs/weixin@2x.png"/>
                                <div class="vue-qr-box">
                                    <vue-qr :text="downloadData.url" colorLight="#fff" :logoSrc="downloadData.icon + '?cache'" :logoScale="0.2" :size="200" :margin="20"></vue-qr>
                                </div>
                            </div>
                        </div>
                        <div class="lct-detail-head-author" v-if="info.author"><strong>主讲嘉宾</strong><span>{{ info.author }}</span></div>
                        <div><strong>直播开始时间</strong> <span>{{ info.start_time }}</span></div>
                        <div class="lct-detail-head-rank_sf">
                            <div class="lct-detail-head-rank_sf-l" v-if="info.rank_sf">
                                <strong>观看权限</strong>
                                <span v-for="(item,index) in info.rank_sf.split(',')" :key="index">{{ item }}</span>
                            </div>
                            <!--<div class="lct-detail-head-rank_sf-c" v-if="info.price">
                                {{ info.price }}
                            </div>-->

                        </div>


                        <div class="lct-detail-head-rank_sf-btn" v-if="info.rank && info.rank.indexOf(0) == -1">
                            <button @click="toPath(info.is_buy == 0?true:false)" :loading="loading" :disabled="loading">{{ info.is_buy == 0?'立即报名':'立即观看' }}</button>
                        </div>
                        <div class="lct-detail-head-rank_sf-btn" v-else>
                            <button @click="toPath(info.is_buy == 0?true:false)" :loading="loading" :disabled="loading">立即观看</button>
                        </div>
                    </div>
                </div>




            </div>
        </div>

        <div class="lct-detail-intro" v-if="info.details">
            <div class="lct-detail-intro-title">
                <strong>课程介绍</strong>
            </div>
            <div v-html="info.details"></div>
            <div v-html="info.remarks"></div>
        </div>


        <el-dialog :visible.sync="payVisible" title="学习联盟" width="400px" :footer="null">
            <div class="modal-txt">
                <vue-qr class="vue-qr" :margin="0" :text="`${baseUrl}/ysxxlm/index.html#/pages/index/approve`" colorDark="#000" colorLight="#fff" :size="200"/>
                <p style="margin-top: 20px;">请先关注公众号去实名认证</p>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="payVisible2" title="学习联盟" width="400px" :footer="null">
            <div class="modal-txt">
                <vue-qr class="vue-qr" :margin="0" :text="`${baseUrl}/ysxxlm/index.html#/pages/live/z_details?id=${$route.params.id}&share=1`" colorDark="#000" colorLight="#fff" :size="200"/>
                <p style="margin-top: 20px;">此直播您还未付费，请去微信扫码去公众号购买此直播</p>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import vueQr from 'vue-qr'
    import config from '@/api/url'
    export default {
        data() {
            return {
                info: {},
                payVisible: false,
                payVisible2: false,
                baseUrl: config.baseUrl,
                downloadData: {
                    url: `https://miniapp.ysxxlm.com/ysxxlm/index.html#/pages/live/z_details?id=${this.$route.params.id}&share=1`,
                    icon: require('../../assets/img/logo.jpg')
                },
                loading: false
            }
        },
        components: {
            'vue-qr': vueQr
        },
        watch:{
            $route(to,from){
                if(to.query.code) {
                    this.loading = true;
                    setTimeout( ()=> {
                        this.getDetail();
                    },2000)
                }
            },
			'$store.state.uid'(value){
				this.loading = true;
				this.getDetail();
			}
        },
        computed: {
            userInfo() {
                let userInfo = '';
                if(this.$store.state.userInfo && this.$store.state.userInfo != undefined){
                    userInfo = this.$store.state.userInfo
                }
                return userInfo
            },
        },
        mounted() {
            this.getDetail();
        },
        methods: {
            getDetail() {
                this.loading = true;
                this.$axios.post(this.apiUrl.details, {
                    id: this.$route.params.id
                }).then(res => {
                    this.info = res.data;
					let metaInfo = {
						title: this.info.title,
						keywords: this.info.tags_keyword,
						description: this.info.title
					}
					this.$store.commit("CAHNGE_META_INFO", metaInfo)
                    this.loading = false;
                    if(this.info.live_state == 4) {
                        this.$router.replace({path: `/seminars/${this.info.vid}/details`})
                    }
                })
            },
            toPath() {
                if(this.info.rank.indexOf(0) == -1 && (!this.userInfo || this.userInfo == undefined)) {
                    this.$store.commit('wxLogin',true);
                    return false
                }
                if(this.info.rank.indexOf(0) == -1 && this.info.can_watch == 0) {
                    this.$message.error('抱歉,您没有观看权限,请去实名认证')
                    this.payVisible = true;
                    return false
                }
                if(this.info.rank.indexOf(0) == -1) {
                    this.$axios.post(this.apiUrl.liveEnroll, {
                        id: this.$route.params.id
                    }).then(res => {
                        if(res.code == 1){
                            if(this.info.is_buy == 0) {
                                this.$message.success("报名成功");
                                if(this.info.live_state == 1) {
                                    // this.$message.warning('抱歉,直播未开始，请等待')
                                    this.getDetail();
                                    return false
                                }
                                this.$router.push({
                                    path: `/seminars/${this.$route.params.id}/play`
                                })
                            }else {
                                if(this.info.live_state == 1) {
                                    this.$message.warning('抱歉,直播未开始，请等待')
                                    this.getDetail();
                                    return false
                                }
                                if(this.info.live_state == 3) {
                                    this.$message.error('抱歉,直播已结束')
                                    return false
                                }
                                this.$router.push({
                                    path: `/seminars/${this.$route.params.id}/play`
                                })
                            }

                        }else{
                            this.payVisible2 = true
                        }
                    })
                }else {
                    if(this.info.live_state == 1) {
                        this.$message.error('抱歉,直播未开始，请等待')
                        return false
                    }
                    if(this.info.live_state == 3) {
                        this.$message.error('抱歉,直播已结束')
                        return false
                    }
                    this.$router.push({
                        path: `/seminars/${this.$route.params.id}/play`
                    })
                }


            }
        }
    }
</script>

<style scoped>

</style>
